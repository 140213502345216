import React from 'react';
import dashboardImage from '../assets/img/dashboardImage.png';
import dashboardImage2 from '../assets/img/dashboardImage2.png';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import logoblack from '../assets/img/logoblack.png';
import overview from '../assets/img/overviewscreen.png'


import metaads from '../assets/img/metaads.png';
import googleads from '../assets/img/googleads.png'
import tiktokads from '../assets/img/tiktokads.png'
import spylogo from '../assets/img/spylogoblue.svg';


import saveadsbrand from '../assets/img/saveadsbrand.png';
import screenapp2 from '../assets/img/screenapp2.png'
import { CheckIcon } from '@heroicons/react/20/solid'
import { translations } from '../translations/translations';
import { useLanguage } from '../contexts/LanguageContext';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


function HomePage() {
    const { language } = useLanguage();
    const t = translations[language];

    return (
        <div >
            <div className='TopDiv'>
                <div className='app-wrapper app-container'>
                    <NavigationBar />

                    <main className="main-content">
                        <h1 className='poppins'>
                            {t.mainTitle.before}{' '}
                            <span className='linear-color-title'>
                                {t.mainTitle.highlight}
                            </span>{' '}
                            {t.mainTitle.after}
                        </h1>
                        <p>{t.subtitle}</p>
                        <div className="image-container">
                            <img src={dashboardImage2} alt="Dashboard" />
                        </div>

                        <div className="link-container">
                            <button className="no-broken-link">
                                <i className="icon-check"></i> Meta
                            </button>
                            <button className="no-broken-link">
                                <i className="icon-check"></i> TikTok
                            </button>
                            <button className="no-broken-link">
                                <i className="icon-check"></i> Google
                            </button>
                            <button className="no-broken-link">
                                <i className="icon-check"></i> Public Share Link
                            </button>
                            <button className="no-broken-link">
                                <i className="icon-check"></i> AI Analytics
                            </button>
                            <button className="no-broken-link">
                                <i className="icon-check"></i> Library
                            </button>
                            <button className="no-broken-link">
                                <i className="icon-check"></i> Socials Analytics
                            </button>
                            <button className="no-broken-link">
                                <i className="icon-check"></i> Filters
                            </button>
                        </div>
                    </main>
                </div>

            </div>

            <div className='SecondDiv'>
                <section className="spy-section app-container">
                    <div className="flex justify-center items-center w-full">
                        <img src={logoblack} alt="Logo" className="mx-auto w-auto h-[50px]" />
                    </div>
                    <h2 className='title-second-div'> <span>{t.spyTitle.highlight}</span>{' '}{t.spyTitle.after}</h2>
                    <p>{t.spySubtitle}</p>
                    <div className="spy-image-container">
                        <img src={dashboardImage} alt="Spy Dashboard" />
                    </div>
                </section>
            </div>
            <div className="overview-container">
                <img src={overview} className='overviewimg' alt="Overview" />
            </div>
            <div className="ads-div ads-section">
                <div className="custom-div position-relative d-flex align-items-center">
                    <div className="row w-100">
                        <div className="col-md-6 position-relative">
                            <img src={metaads} className="meta-image" alt="Meta Ads" />
                        </div>
                        <div className="col-md-6 content-container d-flex flex-column justify-content-center align-items-end text-right">
                            <h2 className="TitleAds"><span className='linear-color-title'>Meta</span> ads</h2>
                        </div>
                    </div>
                </div>


                <div className="custom-div position-relative d-flex align-items-center">
                    <div className="row w-100">
                        <div className="col-md-6 content-container2 d-flex flex-column justify-content-center align-items-end text-right">
                            <h2 className="TitleAds"><span className='linear-color-title'>Google</span> ads</h2>
                        </div>
                        <div className="col-md-6 position-relative">
                            <img src={googleads} className="meta-image2" alt="Google Ads" />
                        </div>
                    </div>
                </div>

                <div className="custom-div position-relative d-flex align-items-center">
                    <div className="row w-100">
                        <div className="col-md-6 position-relative">
                            <img src={tiktokads} className="tiktok-image" alt="Tiktok Ads" />
                        </div>
                        <div className="col-md-6 content-container d-flex flex-column justify-content-center align-items-end text-right">
                            <h2 className="TitleAds"><span className='linear-tiktok-title'>Tiktok</span> ads</h2>
                        </div>
                    </div>
                </div>

            </div>
            <div className="saveads-container">
                <img src={saveadsbrand} className='shareimg' alt="Save Ads Brand" />
            </div>

            <div className="library-div  text-center">

                <main className="main-content">

                    <h1>
                        {t.inspirationTitle.before}{' '}
                        <span className='linear-color-title'>
                            {t.inspirationTitle.highlight}
                        </span>{' '}
                        {t.inspirationTitle.after}
                    </h1>
                    <p>{t.inspirationSubtitle}</p>
                    <div className="flex justify-center items-center w-full pb-20">
                        <img src={screenapp2} alt="Dashboard" className="w-full max-w-5xl mx-auto" />
                    </div>

                </main>
            </div>
            <div className="bottom-div text-center">
                <main className="main-content">
                    <h1 className='white'>
                        <span className='linear-color-title'>
                            {t.bottomSection.title.highlight}
                        </span>{' '}
                        {t.bottomSection.title.after}
                    </h1>
                    <p className='subtext'>{t.bottomSection.subtitle}</p>

                    <div className="flex justify-center items-center w-full pb-20">
                        <img
                            src={screenapp2}
                            alt={language === 'fr' ? "Tableau de bord" : "Dashboard"}
                            className="w-full max-w-5xl mx-auto"
                        />
                    </div>

                    <div className="d-flex justify-content-center w-100">
                        <div className="custom-div2 align-items-center">
                            <div className="row w-100">
                                <div className="col-md-6 d-flex flex-column justify-content-center text-right">
                                    <h2 className="TitleAds">
                                        <span className='linear-color-title'>
                                            {t.bottomSection.metaSection.title.highlight}
                                        </span>{' '}
                                        {t.bottomSection.metaSection.title.after}
                                    </h2>
                                    <p className='white right-ads-text'>
                                        {t.bottomSection.metaSection.description}
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <img
                                        src={spylogo}
                                        className="spy-logo2 img-fluid"
                                        alt={language === 'fr' ? "Publicités Meta" : "Meta Ads"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </main>
            </div>

        </div>
    );
}

export default HomePage;
